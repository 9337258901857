exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bestiary-tsx": () => import("./../../../src/pages/bestiary.tsx" /* webpackChunkName: "component---src-pages-bestiary-tsx" */),
  "component---src-pages-character-tsx": () => import("./../../../src/pages/character.tsx" /* webpackChunkName: "component---src-pages-character-tsx" */),
  "component---src-pages-creator-tsx": () => import("./../../../src/pages/creator.tsx" /* webpackChunkName: "component---src-pages-creator-tsx" */),
  "component---src-pages-feats-tsx": () => import("./../../../src/pages/feats.tsx" /* webpackChunkName: "component---src-pages-feats-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-items-tsx": () => import("./../../../src/pages/items.tsx" /* webpackChunkName: "component---src-pages-items-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-spells-tsx": () => import("./../../../src/pages/spells.tsx" /* webpackChunkName: "component---src-pages-spells-tsx" */)
}

